<template>
	<dashboardPagesLayout>
		<div class="p-inside-manage">
			<div class="page_title">Uploads</div>
			<button type="button" class="create_btn secondary_btn small hide-mobile" v-ripple @click="handleUploadMusic">Upload Music</button>
			<div class="wrap_manage_table">
				<div class="top_bar">
					<div class="tabs_section">
						<ul>
							<li :class="{'active': topFilter === 'all'}" @click="changeTopFilter('all')">All</li>
							<li :class="{'active': topFilter === 'pending'}" @click="changeTopFilter('pending')">Pending</li>
							<li :class="{'active': topFilter === 'published'}" @click="changeTopFilter('published')">Published</li>
							<li :class="{'active': topFilter === 'draft'}" @click="changeTopFilter('draft')">Unpublished</li>
							<li :class="{'active': topFilter === 'deleted'}" @click="changeTopFilter('deleted')">Deleted</li>
						</ul>
					</div>
					<div class="search_section">
						<span class="icon"></span>
						<input type="text" placeholder="Search releases" v-model="searchReleases">
					</div>
				</div>
				<uploadedReleasesTable :dataTable="uploadedReleases"></uploadedReleasesTable>
			</div>
		</div>
		<comingSoonDialog v-if="dialogComingSoon" v-model="dialogComingSoon"></comingSoonDialog>
		<BetaTestingDialog 
			v-if="showPassphraseDialog" 
			v-model="showPassphraseDialog"
			:storage-key="BETA_FEATURES.UPLOAD_MUSIC.storageKey"
			:passphrase="BETA_FEATURES.UPLOAD_MUSIC.passphrase"
			:title="BETA_FEATURES.UPLOAD_MUSIC.title"
			:description="BETA_FEATURES.UPLOAD_MUSIC.description"
			:contact-email="BETA_FEATURES.UPLOAD_MUSIC.contactEmail"
			@verified="onPassphraseVerified"
			@cancelled="showPassphraseDialog = false"
		></BetaTestingDialog>
	</dashboardPagesLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import dashboardPagesLayout from "@/layouts/dashboardPagesLayout";
import uploadedReleasesTable from "@/components/manage/uploadedReleasesTable.vue";
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';
import BetaTestingDialog from "@/components/dialogs/betaTestingDialog";
import { BETA_FEATURES } from "@/config/betaTestingPassphrase";

export default {
	name: "uploads",
	components: {
		dashboardPagesLayout,
		uploadedReleasesTable,
		comingSoonDialog,
		BetaTestingDialog
	},
	data() {
		return {
			searchReleases: '',
			dialogComingSoon: false,
			showPassphraseDialog: false,
			BETA_FEATURES
		}
	},
	watch: {
		topFilter() {
			this.setFirstPage();
			this.getUploadedMusicReleases();
		},
		searchReleases() {
			this.setFirstPage();
			this.getUploadedMusicReleases();
		},
		orderBy() {
			this.setFirstPage();
			this.getUploadedMusicReleases();
		},
		currentPage() {
			this.getUploadedMusicReleases();
		}
	},
	computed: {
		...mapGetters({
			topFilter: 'manageUploadsReleases/TOP_FILTER',
			textSearchReleases: 'manageUploadsReleases/SEARCH_RELEASES',
			uploadedReleases: 'manageUploadsReleases/RELEASES',
			orderBy: 'manageUploadsReleases/ORDER_BY',
			currentPage: 'manageUploadsReleases/CURRENT_PAGE',
			isAdmin: 'isAdmin',
		})
	},
	created() {
		if (this.textSearchReleases.length) {
			this.searchReleases = this.textSearchReleases;
		}
	},
	mounted() {
		this.searchReleases = '';
		this.getUploadedMusicReleases();
	},
	methods: {
		...mapActions({
			getUploadedReleases: 'manageUploadsReleases/GET_RELEASES',
		}),

		getUploadedMusicReleases() {
			let status;
			let search;

			if (this.topFilter !== 'all') {
				status = {status: this.topFilter};
			}
			if (this.searchReleases.length) {
				search = {title: this.searchReleases};
				this.$store.commit('manageUploadsReleases/SET_SEARCH_RELEASES', this.searchReleases);
			} else {
				this.$store.commit('manageUploadsReleases/SET_SEARCH_RELEASES', '');
			}
			let params = Object.assign({currentOwner: true}, status, this.orderBy, {page: this.currentPage}, search);

			this.getUploadedReleases(params)
				.catch(err => console.error(`getUploadedMusicReleases, ${err}`))
		},

		changeTopFilter(filter) {
			this.searchReleases = '';
			this.$store.commit('manageUploadsReleases/SET_TOP_FILTER', filter);
		},
		handleUploadMusic() {
			if (localStorage.getItem(this.BETA_FEATURES.UPLOAD_MUSIC.storageKey) === 'true') {
				this.$router.push({name: 'createMusicRelease', params: {create: true}});
			} else {
				this.showPassphraseDialog = true;
			}
		},
		onPassphraseVerified() {
			this.$router.push({name: 'createMusicRelease', params: {create: true}});
		},
		setFirstPage() {
			this.$store.commit('manageUploadsReleases/SET_CURRENT_PAGE', 1);
		}
	}
}
</script>

<style scoped>
.hide-mobile {
	display: block;
}

@media screen and (max-width: 480px) {
	.hide-mobile {
		display: none;
	}
}
</style>